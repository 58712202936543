import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import Button from 'antd/es/button';

import Header from '../components/header';
import Footer from '../components/footer';
import ExperienceModal from '../components/modal';
import Content from '../components/content';
import SEO from '../components/seo';

import aboutStyles from '../styles/about.module.less';

export default () => {
    const [showModal, setShowModal] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "bg/about_us.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 557) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            introduceImage: file(relativePath: { eq: "bg/intro_bg.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 557) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            advantage: allFile(filter: { relativePath: { regex: "images/advantage/.*/" } }) {
                nodes {
                    childImageSharp {
                        fixed(height: 65) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
    `);


    const advantageList = [
        {
            logo: 'more-safe',
            title: '更安全',
            content: '阿里聚石塔首席合作伙伴'
        },
        {
            logo: 'more-major',
            title: '更专业',
            content: '金麦奖中荣获最佳技术服务类奖'
        },
        {
            logo: 'more-quick',
            title: '更快速',
            content: '订单处理速度峰值 56000单/秒'
        },
        {
            logo: 'more-stability',
            title: '更稳定',
            content: '不停机更新，灰度测试，灰度发布'
        },
        {
            logo: 'more-intimate',
            title: '更贴心',
            content: '一对一的售后服务 7*24小时'
        }
    ];

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <div>
            <SEO title="关于我们" />
            <Header />
            <div className={aboutStyles.aboutHeader}>
                <Img fluid={data.bannerImage.childImageSharp.fluid} />
                <div className={aboutStyles.aboutHeader__content}>
                    <h2>店货通 ERP</h2>
                    <p>专注的感知着你需要的一切</p>
                    <Button
                        className={`${aboutStyles.aboutHeader__button}`}
                        type="primary"
                        onClick={() => setShowModal(true)}
                    >
                        申请体验
                    </Button>
                </div>
            </div>
            <Content className={aboutStyles.weAdvantage}>
                <h2 className={aboutStyles.weAdvantage__title}>我们的优势</h2>
                <ul className={aboutStyles.weAdvantage__list}>
                    {advantageList.map((advantage, index) => (
                        <li className={aboutStyles.weAdvantage__listItem} key={index}>
                            <Img
                                fixed={
                                    data.advantage.nodes.find(
                                        (node) =>
                                            node.childImageSharp.fixed.src.indexOf(advantage.logo) >
                                            -1
                                    ).childImageSharp.fixed
                                }
                                alt={advantage.title}
                            />
                            <p className={aboutStyles.weAdvantage__listItem__title}>
                                {advantage.title}
                            </p>
                            <p className={aboutStyles.weAdvantage__listItem__content} align="left">
                                {advantage.content}
                            </p>
                        </li>
                    ))}
                </ul>
            </Content>
            <div className={aboutStyles.weIntroduce}>
                <Img fluid={data.introduceImage.childImageSharp.fluid} />
                <div className={aboutStyles.weIntroduce__body}>
                    <Content>
                        <h2 className={aboutStyles.weIntroduce__title}>公司介绍</h2>
                        <p className={aboutStyles.weIntroduce__content} align="left">
                            店货通ERP是上海慧仓信息技术有限公司旗下品牌，主要针对电商业务，线上线下业务一体化，整合了进销存、网店订单和售后处理、仓储管理和财务管理等应用。致力于为中小型商贸企业提供免安装、免维护、年付费方式的云端软件（SaaS模式交付），帮助企业更好地提升效率，降低成本。店货通的品牌主张是
                            “ 简单、好用、不贵 ” 。在软件设计上，我们充分考虑到大多数中小型企业的
                            实际业务场景和业务流程，尽力做到易学易用，懂业务就能用的软件。
                            我们将长期为客户提供具有市场竞争力的价格。
                        </p>
                    </Content>
                </div>
            </div>
            <ExperienceModal showModal={showModal} onCancel={handleCancel} />
            <Footer />
        </div>
    );
};
